import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { PdfService } from '../../../../../../shared/service/backend/pdf.service';
import { Ticket } from '../../../../../../shared/models/Ticket';
import { TicketsRevertService } from '../../../../../../shared/service/backend/tickets-revert.service';
import { VendorsService } from '../../../../../../shared/service/backend/vendors.service';
import { printPdf, downloadPdf } from '../../../../../../shared/service/utils/print-to-pdf';
import { MainLoaderService } from 'src/shared/service/rx/main-loader.service';

@Component({
  selector: 'app-tickets-modal',
  templateUrl: './tickets-modal.component.html',
  styleUrls: ['./tickets-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsModalComponent {
  @Input() tickets: Ticket[] = [];
  @Input() orderId: string;
  @Input() eventId: number;
  @Input() isVendor: boolean;
  @Input() simplePreview: boolean = false;
  @Input() actionMode: 'print' | 'return' | 'invite' = 'print';

  @Output() onActionComplete: EventEmitter<void> = new EventEmitter();

  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  confirmOrderDeleteModal: NzModalRef;
  listOfCurrentPageData: ReadonlyArray<Ticket> = [];

  withPrice = false;

  constructor(
    private pdfService: PdfService,
    private ticketsRevertService: TicketsRevertService,
    private modal: NzModalService,
    private vendorsService: VendorsService,
    private loaderService: MainLoaderService,
  ) {}

  downloadA4(isDifferentFiles?: boolean): void {
    const tickets = Array.from(this.setOfCheckedId);

    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете надрукувати квитки?`,
      nzOnOk: () => {
        if (isDifferentFiles) {
          tickets.forEach((el, idx) => {
            this.vendorsService
              .finishCreatingBin(this.orderId)
              .pipe(
                take(1),
                switchMap(() => this.pdfService.generatePdfA4([el], this.withPrice)),
              )
              .subscribe(data => {
                let mediaType = 'application/pdf';
                let blob = new Blob([data], { type: mediaType });
                downloadPdf(URL.createObjectURL(blob), this.orderId, idx + 1);
              });
          });
          this.onActionComplete.emit();
        } else {
          this.loaderService.startLoader();
          this.vendorsService
            .finishCreatingBin(this.orderId)
            .pipe(
              take(1),
              switchMap(() => this.pdfService.generatePdfA4(tickets, this.withPrice)),
            )
            .subscribe(data => {
              this.onActionComplete.emit();
              let mediaType = 'application/pdf';
              let blob = new Blob([data], { type: mediaType });
              downloadPdf(URL.createObjectURL(blob), this.orderId);
              this.loaderService.endLoader();
            });
        }
      },
    });
  }

  downloadThermo(isDifferentFiles?: boolean): void {
    const tickets = Array.from(this.setOfCheckedId);

    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете надрукувати квитки?`,
      nzOnOk: () => {
        if (isDifferentFiles) {
          tickets.forEach((el, idx) => {
            this.vendorsService
              .finishCreatingBin(this.orderId)
              .pipe(
                take(1),
                switchMap(() => this.pdfService.generatePdfTermForInviteNew([el], this.withPrice)),
              )
              .subscribe(data => {
                this.onActionComplete.emit();
                let mediaType = 'application/pdf';
                let blob = new Blob([data], { type: mediaType });
                downloadPdf(URL.createObjectURL(blob), this.orderId, idx + 1);
              });
          });
        } else {
          this.loaderService.startLoader();

          this.vendorsService
            .finishCreatingBin(this.orderId)
            .pipe(
              take(1),
              switchMap(() => this.pdfService.generatePdfTermForInviteNew(tickets, this.withPrice)),
            )
            .subscribe(data => {
              this.onActionComplete.emit();
              let mediaType = 'application/pdf';
              let blob = new Blob([data], { type: mediaType });
              downloadPdf(URL.createObjectURL(blob), this.orderId);
              this.loaderService.endLoader();
            });
        }
      },
    });
  }

  downloadThermoBig(isDifferentFiles?: boolean): void {
    const tickets = Array.from(this.setOfCheckedId);

    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете надрукувати квитки?`,
      nzOnOk: () => {
        if (isDifferentFiles) {
          tickets.forEach((el, idx) => {
            this.vendorsService
              .finishCreatingBin(this.orderId)
              .pipe(
                take(1),
                switchMap(() => this.pdfService.generateBigPdfTermForInviteNew([el], this.withPrice)),
              )
              .subscribe(data => {
                this.onActionComplete.emit();
                let mediaType = 'application/pdf';
                let blob = new Blob([data], { type: mediaType });
                downloadPdf(URL.createObjectURL(blob), this.orderId, idx + 1);
              });
          });
        } else {
          this.loaderService.startLoader();

          this.vendorsService
            .finishCreatingBin(this.orderId)
            .pipe(
              take(1),
              switchMap(() => this.pdfService.generateBigPdfTermForInviteNew(tickets, this.withPrice)),
            )
            .subscribe(data => {
              this.onActionComplete.emit();
              let mediaType = 'application/pdf';
              let blob = new Blob([data], { type: mediaType });
              downloadPdf(URL.createObjectURL(blob), this.orderId);
              this.loaderService.endLoader();
            });
        }
      },
    });
  }

  downloadInviteA4(isDifferentFiles?: boolean): void {
    const tickets: string[] | number[] | string[][] = Array.from(this.setOfCheckedId);

    if (isDifferentFiles) {
      tickets.forEach((el, idx) => {
        this.pdfService
          .generatePdfA4ForInvite([el], this.withPrice)

          .subscribe(data => {
            let mediaType = 'application/pdf';
            let blob = new Blob([data], { type: mediaType });
            downloadPdf(URL.createObjectURL(blob), this.orderId, idx + 1);
          });
      });
    } else {
      this.loaderService.startLoader();

      this.pdfService
        .generatePdfA4ForInvite(tickets, this.withPrice)

        .subscribe(data => {
          let mediaType = 'application/pdf';
          let blob = new Blob([data], { type: mediaType });
          downloadPdf(URL.createObjectURL(blob), this.orderId);
          this.loaderService.endLoader();
        });
    }
  }

  downloadInviteThermo(isDifferentFiles?: boolean): void {
    const tickets: string[] | number[] | string[][] = Array.from(this.setOfCheckedId);

    if (isDifferentFiles) {
      tickets.forEach((el, idx) => {
        this.pdfService
          .generatePdfTermForInviteNew([el], this.withPrice)

          .subscribe(data => {
            let mediaType = 'application/pdf';
            let blob = new Blob([data], { type: mediaType });
            downloadPdf(URL.createObjectURL(blob), this.orderId, idx + 1);
          });
      });
    } else {
      this.loaderService.startLoader();

      this.pdfService
        .generatePdfTermForInviteNew(tickets, this.withPrice)

        .subscribe(data => {
          let mediaType = 'application/pdf';
          let blob = new Blob([data], { type: mediaType });
          downloadPdf(URL.createObjectURL(blob), this.orderId);
          this.loaderService.endLoader();
        });
    }
  }

  downloadInviteThermoBig(isDifferentFiles?: boolean): void {
    const tickets: string[] | number[] | string[][] = Array.from(this.setOfCheckedId);

    if (isDifferentFiles) {
      tickets.forEach((el, idx) => {
        this.pdfService
          .generateBigPdfTermForInviteNew([el], this.withPrice)

          .subscribe(data => {
            let mediaType = 'application/pdf';
            let blob = new Blob([data], { type: mediaType });
            downloadPdf(URL.createObjectURL(blob), this.orderId, idx + 1);
          });
      });
    } else {
      this.loaderService.startLoader();

      this.pdfService
        .generateBigPdfTermForInviteNew(tickets, this.withPrice)

        .subscribe(data => {
          let mediaType = 'application/pdf';
          let blob = new Blob([data], { type: mediaType });
          downloadPdf(URL.createObjectURL(blob), this.orderId);
          this.loaderService.endLoader();
        });
    }
  }

  onCurrentPageDataChange($event: ReadonlyArray<Ticket>): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  printA4(ticketIds: number[] = null): void {
    const tickets = ticketIds ?? Array.from(this.setOfCheckedId);
    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете надрукувати квитки?`,
      nzOnOk: () => {
        this.vendorsService
          .finishCreatingBin(this.orderId)
          .pipe(
            take(1),
            switchMap(() => this.pdfService.generatePdfA4(tickets, this.withPrice)),
          )
          .subscribe(data => {
            this.onActionComplete.emit();
            let mediaType = 'application/pdf';
            let blob = new Blob([data], { type: mediaType });
            printPdf(URL.createObjectURL(blob));
          });
      },
    });
  }

  printThermo(ticketIds: number[] = null): void {
    const tickets = ticketIds ?? Array.from(this.setOfCheckedId);

    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете надрукувати квитки?`,
      nzOnOk: () => {
        this.vendorsService
          .finishCreatingBin(this.orderId)
          .pipe(
            take(1),
            switchMap(() => this.pdfService.generatePdfTermForInviteNew(tickets, this.withPrice)),
          )
          .subscribe(data => {
            this.onActionComplete.emit();
            let mediaType = 'application/pdf';
            let blob = new Blob([data], { type: mediaType });
            printPdf(URL.createObjectURL(blob));
          });
      },
    });
  }

  printThermoBig(ticketIds: number[] = null): void {
    const tickets = ticketIds ?? Array.from(this.setOfCheckedId);

    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете надрукувати квитки?`,
      nzOnOk: () => {
        this.vendorsService
          .finishCreatingBin(this.orderId)
          .pipe(
            take(1),
            switchMap(() => this.pdfService.generateBigPdfTermForInviteNew(tickets, this.withPrice)),
          )
          .subscribe(data => {
            this.onActionComplete.emit();
            let mediaType = 'application/pdf';
            let blob = new Blob([data], { type: mediaType });
            printPdf(URL.createObjectURL(blob));
          });
      },
    });
  }

  returnTicketByIdMultiple(): void {
    const selectedIds: number[] = Array.from(this.setOfCheckedId);
    const selectedBarCodes: string[] = selectedIds.map(id => this.tickets.find(ticket => ticket.id === id).barcode);
    this.returnTicketById(selectedBarCodes);
  }

  returnTicketById(ticketBarcode: string | string[]): void {
    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви підтверджуєте повернення квитків?`,
      nzContent: 'Квитки будуть повернуті в продаж. Не забудьте забрати фізичні квитки, що повертають!',
      nzOnOk: () =>
        this.ticketsRevertService
          .revertTicket(this.eventId, ticketBarcode, 'test')

          .subscribe(() => this.onActionComplete.emit()),
    });
  }

  returnTicketByOrder(): void {
    const options = {
      order: String(this.orderId),
      idEvent: this.eventId,
      text: 'return from vendor',
    };

    this.confirmOrderDeleteModal = this.modal.confirm({
      nzTitle: `Ви дійсно хочете повернути замовлення ${this.orderId}?`,
      nzOnOk: () => {
        this.ticketsRevertService
          .returnOrderId(options)

          .subscribe(() => this.onActionComplete.emit());
      },
    });
  }

  printInviteA4(ticketsNumber: string[] | null = null): void {
    const tickets: string[] | number[] | string[][] = ticketsNumber ? [ticketsNumber] : Array.from(this.setOfCheckedId);

    this.pdfService
      .generatePdfA4ForInvite(tickets, this.withPrice)

      .subscribe(data => {
        let mediaType = 'application/pdf';
        let blob = new Blob([data], { type: mediaType });
        printPdf(URL.createObjectURL(blob));
      });
  }

  printInviteThermo(ticketsNumber: string[] | null = null): void {
    const tickets: string[] | number[] | string[][] = ticketsNumber ? [ticketsNumber] : Array.from(this.setOfCheckedId);

    this.pdfService
      .generatePdfTermForInviteNew(tickets, this.withPrice)

      .subscribe(data => {
        let mediaType = 'application/pdf';
        let blob = new Blob([data], { type: mediaType });
        printPdf(URL.createObjectURL(blob));
      });
  }

  printInviteThermoBig(ticketsNumber: string[] | null = null): void {
    const tickets: string[] | number[] | string[][] = ticketsNumber ? [ticketsNumber] : Array.from(this.setOfCheckedId);

    this.pdfService
      .generateBigPdfTermForInviteNew(tickets, this.withPrice)

      .subscribe(data => {
        let mediaType = 'application/pdf';
        let blob = new Blob([data], { type: mediaType });
        printPdf(URL.createObjectURL(blob));
      });
  }

  private refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  private updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
}
