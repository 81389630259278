import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { openCloseElementFlex } from '../../../../shared/service/utils/export-functions';
import { UserDetailsService } from '../../../../shared/service/user-details-service.service';
import { LocationService } from '../../../../shared/service/backend/location.service';
import { CategoryService } from '../../../../shared/service/backend/category.service';
import { MainLoaderService } from '../../../../shared/service/rx/main-loader.service';
import { HallService } from '../../../../shared/service/backend/hall.service';
import { City } from '../../../../shared/models/City';
import { Category } from '../../../../shared/models/Category';
import { PlatformHall } from '../../../../shared/models/PlatformHall';
import { EventFilterType } from '../../../../shared/models/enums/enums';
import { BookingService } from '../../../../shared/service/backend/booking.service';
import { Page } from '../../../../shared/models/Page';
import { BoxOfficeEventDto } from '../../../../shared/models/dto/BoxOfficeEventDto';
import { MainSettingConfig, MainSettingsService } from '../../../../shared/service/rx/main-settings.service';
import { TicketsRevertService } from '../../../../shared/service/backend/tickets-revert.service';
import { SideInfo, SideInfoRxService } from '../../../source/side-info-bar/side-info-bar.component';
import { UserWithBoxOfficeDto } from '../../../../shared/models/dto/UserWithBoxOfficeDto';
import { take } from 'rxjs/operators';

export class ReturnTicketModel {
  idEvent: number;
  idTicket: number;
  text: string;
}

@Component({
  selector: 'app-returned-tickets',
  templateUrl: './returned-tickets.component.html',
  styleUrls: ['./returned-tickets.component.css'],
})
export class ReturnedTicketsComponent implements OnInit {
  lang: string;

  allCities: City[] = [];
  categories: Category[] = [];
  platformHalls: PlatformHall[] = [];

  filterEventFilterType: EventFilterType = 'ALL';
  eventType: 'ACTIVE' | 'ARCHIVE' = 'ACTIVE';
  filterCity: number;
  filterPlatformHall: number;
  filterCategory: number;
  filterMonth: number;
  filterDateFrom: string = undefined;
  filterDateTo: string = undefined;
  count: number = 10;
  page: number = 0;

  public returnForm: FormGroup;

  events: Page<BoxOfficeEventDto>;
  configSetting: MainSettingConfig = new MainSettingConfig();

  returnOneInfo: ReturnTicketModel = new ReturnTicketModel();

  priceOneTicket: number = 0;

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  sort: 'ASC' | 'DESC' = 'ASC';

  constructor(
    private fb: FormBuilder,
    private userDetailService: UserDetailsService,
    private locationService: LocationService,
    private categoryService: CategoryService,
    private mainLoader: MainLoaderService,
    private hallService: HallService,
    private translateService: TranslateService,
    private bookingService: BookingService,
    private mainSettingsService: MainSettingsService,
    private ticketsRevertService: TicketsRevertService,
    private sideInfoRxService: SideInfoRxService,
  ) {
    this.user = this.userDetailService.getUser();
    this.userDetailService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );
    this.categoryService.findAll().subscribe(
      next => {
        this.categories = next;
      },
      error => {
        console.error(error);
      },
    );
    this.locationService.findAllCity().subscribe(
      next => {
        this.allCities = next;
      },
      error => {
        console.error(error);
      },
    );
    this.configSetting = this.mainSettingsService.getConfig();
    this.mainSettingsService.settingConfig$.subscribe(
      next => {
        this.configSetting = next;
      },
      error => {
        console.error(error);
      },
    );
    this.loadEventWithFilter();
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    this.returnForm = this.fb.group({
      isReturnedTicket: [true],
      returnedTicketNumber: [null, Validators.required],
      returnedTicketReason: [null, Validators.required],
      returnedOrderNumber: [null, Validators.required],
      returnedOrderReason: [null, Validators.required],
    });
  }

  public get isReturnedTicket(): boolean {
    return this.returnForm.get('isReturnedTicket').value;
  }

  private get returnedTicket(): Record<string, string> {
    return {
      ticketId: this.returnForm.get('returnedTicketNumber').value,
      ticketReturnReason: this.returnForm.get('returnedTicketReason').value,
    };
  }

  private get returnedOrder(): Record<string, string> {
    return {
      orderId: this.returnForm.get('returnedOrderNumber').value,
      orderReturnReason: this.returnForm.get('returnedOrderReason').value,
    };
  }

  loadRelevantMonths(): number[] {
    let startDate = new Date();
    let months: number[] = [];
    for (let i = startDate.getMonth(); i < 12; i++) {
      months.push(i);
    }
    return months;
  }

  changeFilterCity(val) {
    this.filterPlatformHall = undefined;
    this.loadEventWithFilter();
    if (val && val !== 'undefined') {
      this.hallService.findAllPlatformHallsByCity(val).subscribe(
        next => {
          this.platformHalls = next;
        },
        error => {
          console.error(error);
        },
      );
    } else {
      this.hallService.findAllPlatformHallsAvailable().subscribe(res => (this.platformHalls = res));
    }
  }

  getPriceOneTicket(id: string, id2: string): void {
    this.isReturnedTicket ? this.returnTicket(id, id2) : this.returnOrder(id, id2);
  }

  private returnTicket(id: string, id2: string): void {
    if (!this.returnedTicket.ticketId && !this.returnedTicket.ticketReturnReason) return;

    this.ticketsRevertService.getPrice(this.returnOneInfo.idEvent, this.returnedTicket.ticketId).subscribe(
      res => {
        if (!this.siteOrderCheckConfirm(res.orderFrom, false)) return;
        this.openAndCloseTickRet(id, true);
        this.openAndCloseTickRet(id2, true);
        this.priceOneTicket = res.price;
      },
      error => {
        this.sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка! Можливо такого квитка не існує?').setDate().setError(''));
        console.error(error);
      },
    );
  }

  siteOrderCheckConfirm(from: 'kasa' | 'site', isOrder: boolean) {
    return from === 'site'
      ? confirm(`${isOrder ? 'Данe замовлення було куплене' : 'Даний квиток був куплений'} на сайті, чи бажаєте його повернути?`)
      : true;
  }

  private returnOrder(id: string, id2: string): void {
    if (!this.returnedOrder.orderId && !this.returnedOrder.orderReturnReason) return;

    this.ticketsRevertService
      .getOrderPrice(this.returnOneInfo.idEvent, this.returnedOrder.orderId, this.returnedOrder.orderReturnReason)
      .subscribe(
        res => {
          if (!this.siteOrderCheckConfirm(res.orderFrom, true)) return;
          this.openAndCloseTickRet(id, true);
          this.openAndCloseTickRet(id2, true);
          this.priceOneTicket = res.price;
        },
        error => {
          this.sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка! Можливо такого замовлення не існує?').setDate().setError(''));
          console.error(error);
        },
      );
  }

  trackById(index, obj) {
    return obj.id;
  }

  loadEventWithFilter() {
    this.page = 0;
    this.loadEventWithFilterPage();
  }

  loadEventWithFilterPage() {
    this.mainLoader.startLoader();
    this.bookingService
      .filterEventsBoxOfficeStatistic(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        this.filterMonth,
        this.filterDateFrom,
        this.filterDateTo,
        this.sort,
        this.eventType,
      )
      .subscribe(
        next => {
          this.events = next;
          this.mainLoader.endLoader();
        },
        error => {
          this.mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  loadEventWithResearcher(value) {
    if (value.length > 0) {
      this.page = 0;
      this.mainLoader.startLoader();
      this.bookingService
        .filterEventsBoxOfficeStatisticResearcher(value, (this.lang == 'ua' ? 'uk' : this.lang).toLocaleUpperCase(), this.sort)
        .subscribe(
          next => {
            this.events = next;
            this.filterCity = undefined;
            this.filterPlatformHall = undefined;
            this.filterCategory = undefined;
            this.filterMonth = undefined;
            this.filterDateFrom = undefined;
            this.filterDateTo = undefined;
            this.mainLoader.endLoader();
          },
          error => {
            this.mainLoader.endLoader();
            console.error(error);
          },
        );
    } else {
      this.resetFilter();
    }
  }

  loadEventWithFilterEventType() {
    this.page = 0;
    this.mainLoader.startLoader();
    this.filterMonth = undefined;
    this.filterDateFrom = undefined;
    this.filterDateTo = undefined;
    this.bookingService
      .filterEventsBoxOfficeStatistic(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        undefined,
        undefined,
        undefined,
        this.sort,
      )
      .subscribe(
        next => {
          this.events = next;
          this.mainLoader.endLoader();
        },
        error => {
          this.mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  loadEventWithFilterMonth() {
    this.page = 0;
    this.mainLoader.startLoader();
    this.filterEventFilterType = 'ALL';
    this.filterDateFrom = undefined;
    this.filterDateTo = undefined;
    this.bookingService
      .filterEventsBoxOfficeStatistic(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        this.filterMonth,
        undefined,
        undefined,
        this.sort,
      )
      .subscribe(
        next => {
          this.events = next;
          this.mainLoader.endLoader();
        },
        error => {
          this.mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  loadEventWithFilterDate() {
    this.page = 0;
    this.loadEventWithFilterDatePage();
  }

  loadEventWithFilterDatePage(type?: boolean) {
    this.mainLoader.startLoader();
    this.filterEventFilterType = 'ALL';
    this.filterMonth = undefined;
    this.bookingService
      .filterEventsBoxOfficeStatistic(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        undefined,
        this.filterDateFrom,
        this.filterDateTo,
        this.sort,
      )
      .subscribe(
        next => {
          this.events = next;
          if (type != null) this.setDefaultDataToInput(type);
          this.mainLoader.endLoader();
        },
        error => {
          this.mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  setDefaultDataToInput(type: boolean) {
    if (!(<HTMLInputElement>event.target).value) return;
    let date = new Date();
    if (type) {
      if (!this.filterDateFrom || this.filterDateFrom == '') {
        this.filterDateFrom = date.toISOString().slice(0, 10);
      }
    } else {
      if (!this.filterDateTo || this.filterDateTo == '') {
        this.filterDateTo =
          +date.toISOString().slice(0, 10).slice(0, 4) +
          1 +
          '-' +
          date.toISOString().slice(0, 10).slice(5, 8) +
          date.toISOString().slice(0, 10).slice(8, 10);
      }
    }
  }

  resetFilter() {
    this.filterEventFilterType = 'ALL';
    this.filterCity = undefined;
    this.filterPlatformHall = undefined;
    this.filterCategory = undefined;
    this.filterMonth = undefined;
    this.filterDateFrom = undefined;
    this.filterDateTo = undefined;
    this.loadEventWithFilter();
    (document.getElementById('researcher') as any).value = '';
  }

  public confirmReturn(): void {
    this.isReturnedTicket ? this.returnOneTicket() : this.returnOrderProceed();
    this.openAndCloseTickRet('tickRetOpenNext');
  }

  public discardReturn(): void {
    this.returnOneInfo.text = '';
    this.returnOneInfo.idTicket = null;
    this.returnOneInfo.idEvent = null;
    this.returnForm.reset();
    this.openAndCloseTickRet('tickRetOpenNext');
  }

  private returnOneTicket() {
    const { ticketId, ticketReturnReason } = this.returnedTicket;

    this.ticketsRevertService
      .returnTicketId({
        idEvent: this.returnOneInfo.idEvent,
        order: ticketId,
        text: ticketReturnReason,
      })
      .subscribe(
        next => {
          this.sideInfoRxService.add(new SideInfo().setInfo('Квиток успішно повернуто!').setDate());
          this.returnOneInfo.text = '';
          this.returnOneInfo.idTicket = null;
          this.returnOneInfo.idEvent = null;
          this.returnForm.reset();
        },
        error => {
          this.sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка! Можливо такого квитка не існує?').setDate().setError(''));
          this.returnOneInfo.text = '';
          this.returnOneInfo.idTicket = null;
          this.returnOneInfo.idEvent = null;
        },
      );
  }

  private returnOrderProceed(): void {
    const options = {
      order: this.returnedOrder.orderId,
      text: this.returnedOrder.orderReturnReason,
      idEvent: this.returnOneInfo.idEvent,
    };

    this.ticketsRevertService
      .returnOrderId(options)
      .pipe(take(1))
      .subscribe(
        () => {
          this.sideInfoRxService.add(new SideInfo().setInfo('Замовлення успішно повернуто!').setDate());
          this.returnOneInfo.text = '';
          this.returnOneInfo.idTicket = null;
          this.returnOneInfo.idEvent = null;
          this.returnForm.reset();
        },
        error => {
          this.sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка! Можливо такого замовлення не існує?').setDate().setError(''));
          this.returnOneInfo.text = '';
          this.returnOneInfo.idTicket = null;
          this.returnOneInfo.idEvent = null;
        },
      );
  }

  open(i) {
    this.page = i;
    if (this.filterMonth) {
      this.loadEventWithFilterDatePage();
    } else {
      this.loadEventWithFilterPage();
    }
  }

  changeCount(i) {
    this.page = 0;
    this.count = i;
    if (this.filterMonth) {
      this.loadEventWithFilterDatePage();
    } else {
      this.loadEventWithFilterPage();
    }
  }

  openAndCloseTickRet(id: string, disableReset?: boolean) {
    openCloseElementFlex(document.getElementById(id));
    if (!disableReset) {
      this.returnForm.reset();
      this.returnForm.controls.isReturnedTicket.setValue(true);
    }
  }

  openCloseBlock(el) {
    openCloseElementFlex(el);
  }

  onEventStatusChange(event) {
    this.eventType = event.target.value;
    this.loadEventWithFilter();
  }
}
