import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Bin } from '../../../../../shared/models/Bin';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { User } from '../../../../../shared/models/User';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { PdfService } from '../../../../../shared/service/backend/pdf.service';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { SideInfo, SideInfoRxService } from '../../../../source/side-info-bar/side-info-bar.component';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-invite-container-one',
  templateUrl: './invite-container-one.component.html',
  styleUrls: ['./invite-container-one.component.css'],
})
export class InviteContainerOneComponent implements OnInit, AfterViewInit {
  invite: Bin = new Bin();
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  ticketsForPrint: number[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userDetailService: UserDetailsService,
    private _bookingService: BookingService,
    private _pdfService: PdfService,
    private _mainLoaderService: MainLoaderService,
    private _sideInfoRxService: SideInfoRxService,
  ) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this._activatedRoute.params.subscribe(
      next => {
        this._bookingService.findByBinId(next['id']).subscribe(
          next => {
            this.invite = next;
          },
          error => {
            console.error(error);
          },
        );
      },
      error => {
        console.error(error);
      },
    );
  }

  setAllCheck() {
    this.invite.tickets.forEach(value => {
      this.ticketsForPrint.push(value.id);
      (<HTMLInputElement>document.getElementById('check_active_' + value.id)).checked = true;
      (<HTMLInputElement>document.getElementById('check_active_all')).checked = true;
    });
  }

  changeTicketInBinForPrint(all: boolean, i?: number, idTicket?: number) {
    if (all) {
      this.ticketsForPrint = [];
      this.invite.tickets.forEach(value => {
        if ((<HTMLInputElement>document.getElementById('check_active_all')).checked) {
          this.ticketsForPrint.push(value.id);
          (<HTMLInputElement>document.getElementById('check_active_' + value.id)).checked = true;
        } else {
          this.ticketsForPrint = [];
          (<HTMLInputElement>document.getElementById('check_active_' + value.id)).checked = false;
        }
      });
    } else {
      if ((<HTMLInputElement>document.getElementById('check_active_' + idTicket)).checked == false) {
        (<HTMLInputElement>document.getElementById('check_active_' + idTicket)).checked = false;
        this.ticketsForPrint = this.ticketsForPrint.filter(value => value != idTicket);
        (<HTMLInputElement>document.getElementById('check_active_all')).checked = false;
      } else {
        (<HTMLInputElement>document.getElementById('check_active_' + idTicket)).checked = true;
        this.ticketsForPrint.push(idTicket);
      }
    }
  }

  printTickets(typePrint: 'TERM' | 'A4' | 'BIG') {
    this._mainLoaderService.startLoader();
    if (this.ticketsForPrint.length == 0) {
      this._sideInfoRxService.add(new SideInfo().setInfo('Позначте які квитки для друку!').setDate().setError(''));
      return;
    }
    if (typePrint == 'TERM') {
      this._pdfService.generatePdfTermForInviteNew(this.ticketsForPrint).subscribe(
        next => {
          let mediaType = 'application/pdf';
          let blob = new Blob([next], { type: mediaType });
          this.printDocument('ticket-document', URL.createObjectURL(blob));
        },
        error => {
          this._mainLoaderService.endLoader();
          this._sideInfoRxService.add(new SideInfo().setInfo('Сталася помилка!').setDate().setError(''));
          console.error(error);
        },
      );
    } else if (typePrint == 'BIG') {
      this._pdfService.generateBigPdfTermForInviteNew(this.ticketsForPrint).subscribe(
        next => {
          let mediaType = 'application/pdf';
          let blob = new Blob([next], { type: mediaType });
          this.printDocument('ticket-document', URL.createObjectURL(blob));
        },
        error => {
          this._mainLoaderService.endLoader();
          console.error(error);
        },
      );
    } else {
      this._pdfService.generatePdfA4ForInvite(this.ticketsForPrint).subscribe(
        next => {
          let mediaType = 'application/pdf';
          let blob = new Blob([next], { type: mediaType });
          this.printDocument('ticket-document', URL.createObjectURL(blob));
        },
        error => {
          this._mainLoaderService.endLoader();
          this._sideInfoRxService.add(new SideInfo().setInfo('Сталася помилка!').setDate().setError(''));
          console.error(error);
        },
      );
    }
  }

  printDocument(documentId, path) {
    // let doc: any = document.getElementById(documentId);
    // doc.src = path;
    // this.printEmb(doc);
    this.printPdf(path);
    //Wait until PDF is ready to print
  }

  printPdf(url) {
    let iframe;
    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = () => {
      setTimeout(() => {
        this._mainLoaderService.endLoader();
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  ngAfterViewInit(): void {
    if (
      <HTMLInputElement>document.getElementById('check_active_all') &&
      this.invite.tickets.length > 0 &&
      <HTMLInputElement>document.getElementById('check_active_' + this.invite.tickets[0].id)
    ) {
      this.setAllCheck();
    } else {
      setTimeout(() => {
        this.ngAfterViewInit();
      }, 500);
    }
  }

  ngOnInit() {}
}
